.WorkExperience {
    min-width: 280px;
    height: fit-content;
    margin: var(--def-margin);
    margin-bottom: calc(var(--def-margin) * 2);
    padding: calc(var(--def-padding) / 2);
    box-sizing: border-box;
    border-bottom: 1px solid var(--primary-color);
    font-family: var(--txt-font-family);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.WorkExperience__enterprise {
    display: flex;
    align-items: center;
    margin-bottom: var(--def-margin);
}

.WorkExperience__enterprise p {
    margin: 0;
}

.WorkExperience__enterprise-label {
    margin: 0;
    margin-right: var(--def-margin);
}

.WorkExperience__enterprise a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.WorkExperience__content > div {
    margin-bottom: var(--def-margin);
}

.WorkExperience__position {
    font-weight: 500;
    font-style: italic;
}

.WorkExperience__tasks span,
.WorkExperience__tools span {
    font-weight: bold;
}

.WorkExperience__tools div {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.WorkTool {
    width: fit-content;
    padding: calc(var(--def-padding) / 2);
    border: 1px solid #4dabf5;
    border-radius: 6px;
}
