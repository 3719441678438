.header {
    width: 100%;
    min-width: var(--min-width);
    box-sizing: border-box;
    margin-bottom: var(--def-margin);
}

.header__profile-navbar {
    display: flex;
    align-items: end;
    height: 50px;
    padding-left: var(--def-padding);
    padding-right: var(--def-padding);
    font-size: 16pt;
    font-weight: bold;
    background-color: #000;
    box-shadow: 0 4px 3px #1c7fcf52;
}

.header__profile-navbar h4 {
    margin: 0;
    padding: 8px 0;
}

.header__title {
    font-size: 14pt;
    font-weight: 300;
}

.header__profile {
    width: 300px;
    margin: 0 auto;
    border-radius: 0 0 16px 16px;
    padding: var(--def-padding) 8px 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    box-sizing: border-box;
}

.header__title,
.header__profile-cover {
    margin: 0 auto;
    text-align: center;
}
.header__profile-cover {
    display: block;
    width: 200px;
    height: 250px;
    margin-top: var(--def-padding);
    border-radius: 50%;
    object-fit: cover;
}
