.WorkExperienceList {
    width: 100%;
    min-width: var(--min-width);
    padding: var(--def-padding);
    animation: toTopAnimation 2s ease-out forwards;
    box-sizing: border-box;
}

.WorkExperienceList__container {
    height: 380px;
    padding: var(--def-padding) 0;
    overflow-y: scroll;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2) inset;
}

.WorkExperienceList__container::-webkit-scrollbar {
    display: none;
}

.WorkExperienceList__container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.WorkExperienceList__label {
    width: 100%;
    border-bottom: 1px solid #fff;
}
