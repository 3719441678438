:root {
    --min-width: 320px;
    --def-padding: 16px;
    --def-margin: 16px;
    --page-font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    --txt-font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
    --primary-color: #4dabf5;
}

body {
    margin: 0;
    padding: 0;
    color: #fff;
    font-weight: 200;
    font-family: var(--page-font-family);
    background: linear-gradient(#000, #4dabf5);
    word-spacing: 0px;
    box-sizing: border-box;
}

h1,
h2,
h3 {
    line-height: 35px;
}

p {
    line-height: 20px;
    text-align: justify;
}

#app {
    box-sizing: border-box;
}

@keyframes toTopAnimation {
    from {
        transform: translateY(100vh);
        opacity: 0;
        background-color: var(--primary-color);
    }
    to {
        transform: translateY(0);
        opacity: 1;
        background-color: unset;
    }
}
